@font-face {
  font-family: "Sarabun";
  font-weight: normal;
  src: url("../fonts/Sarabun/Sarabun-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Sarabun";
  font-weight: bold;
  src: url("../fonts/Sarabun/Sarabun-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "ThaiSans Neue";
  font-weight: normal;
  src: url("../fonts/ThaiSans Neue/ThaiSansNeue-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ThaiSans Neue";
  font-weight: bold;
  src: url("../fonts/ThaiSans Neue/ThaiSansNeue-Bold.ttf") format("truetype");
}
