@import url("normalize.css");
@import url("fontawesome/css/all.css");
@import url("fonts.css");

* {
  box-sizing: border-box;
  font-size: 16px;
}

body {
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  letter-spacing: 0;
}

.k-filtercell .k-filtercell-operator > .k-button.k-clear-button-visible {
  margin-left: 0.375rem;
}
.k-list .k-item.k-state-selected {
  background-color: #eeeef2;
  color: #666666;
}
.k-popup {
  padding: 0px;
  background: #f7f7f9;
  margin-top: 3px;
  border: none;
}
.k-list .k-item {
  min-height: 38px;
}
.k-animation-container {
  z-index: 10001;
}
.k-treeview .k-in.k-state-focused {
  box-shadow: none;
}

.k-list-scroller > ul > li:hover,
.k-list-scroller > ul > li.k-state-selected {
  background-color: #0275d8 !important;
  color: white !important;
}

.__react_modal_image__modal_container {
  z-index: 10005 !important;
}

.k-widget {
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  color: #666666;
  letter-spacing: 0;
}

.k-grid-columnmenu-popup {
  width: 298px;
}

.k-tooltip {
  margin-left: 21px;
  background-color: #0275d8;
}

.k-tooltip > .k-tooltip-content {
  background-color: #0275d8;
  color: #ffffff;
}

.k-tooltip > .k-callout.k-callout-w {
  color: #0275d8;
}

/* .k-dialog-wrapper {
  z-index: 10002;
} */
